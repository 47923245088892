<!-- 客群管理 -->
<template>
  <div class="about_us">
    <section class="part1">
      <img class="banner_bg" src="@/assets/images/home/banner3.png" alt="" />
      <div class="w">
        <div class="text_box" id="textBox">我们用心提供全面的产品与服务</div>
        <div class="more_icon">
          <img src="@/assets/images/home/more_icon.png" alt="" />
        </div>
      </div>
    </section>

    <section class="part2">
      <div class="block">
        <div class="w">
          <h4>企业介绍</h4>
          <div class="desc">
            灯塔互动于2016年07月01日成立，系灯塔财经信息有限（灯塔财经）旗下全资控股子公司。公司致力于各领域内的科技创新，运用人工智能及大数据，为客户提供全方位的产品和服务，帮助用户搭建从内容创作-渠道分发-获客管理-用户分析-精准营销，商业变现的全流程服务。
          </div>
        </div>
      </div>
      <div class="block" style="background: #f1f7fb">
        <div class="w">
          <h4>灯塔旗下产品</h4>
          <ul class="nav">
            <li @click="cutSwipe(0)" :class="swipeIndex == 0 ? 'active' : ''">
              <i><img src="@/assets/images/home/nav1.png" alt="" /></i>
              <span>股票灯塔</span>
            </li>
            <li @click="cutSwipe(1)" :class="swipeIndex == 1 ? 'active' : ''">
              <i><img src="@/assets/images/home/nav2.png" alt="" /></i>
              <span>灯影</span>
            </li>
            <li @click="cutSwipe(2)" :class="swipeIndex == 2 ? 'active' : ''">
              <i><img src="@/assets/images/home/nav3.png" alt="" /></i>
              <span>弹指营</span>
            </li>
            <li @click="cutSwipe(3)" :class="swipeIndex == 3 ? 'active' : ''">
              <i><img src="@/assets/images/home/nav4.png" alt="" /></i>
              <span>DOCK分发</span>
            </li>
          </ul>
          <ul class="swipe_box">
            <div class="swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="operate">
                    <div class="download">
                      <div class="android">
                        <img src="@/assets/images/home/android.png" alt="" />

                        <div class="android_code">
                          <img
                            src="@/assets/images/home/android_code.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="ios">
                        <img src="@/assets/images/home/ios.png" alt="" />
                        <div class="ios_code">
                          <img src="@/assets/images/home/ios_code.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="show">
                    <img src="@/assets/images/home/swipe_dengta.png" alt="" />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="operate">
                    <div
                      class="knowmore"
                      @click="knowMore('https://dtying.com/')"
                    >
                      了解更多
                    </div>
                  </div>
                  <div class="show">
                    <img src="@/assets/images/home/swipe_dengying.png" alt="" />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="operate">
                    <div
                      class="knowmore"
                      @click="knowMore('https://tanzhiying.com/index')"
                    >
                      了解更多
                    </div>
                  </div>
                  <div class="show">
                    <img
                      src="@/assets/images/home/swipe_tanzhiying.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="operate">
                    <div
                      class="knowmore"
                      @click="knowMore('https://dock.tanzhiw.com/')"
                    >
                      了解更多
                    </div>
                  </div>
                  <div class="show">
                    <img src="@/assets/images/home/swipe_dock.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div class="block">
        <div class="w">
          <h4>服务成就</h4>
          <div class="desc">
            灯塔互动于2016年07月01日成立，系灯塔财经信息有限公司（灯塔财经）旗下全资控股子公司。公司致力于各领域内的科技创新，运用人工智能及大数据，为客户提供全方位的产品和服务，帮助用户搭建从内容创作-渠道分发-获客管理-用户分析-精准营销-商业变现的全流程服务。
          </div>
          <div class="achievement">
            <div>
              <img src="@/assets/images/home/achievement1.png" alt="" />
            </div>
            <div>
              <img src="@/assets/images/home/achievement2.png" alt="" />
            </div>
            <div>
              <img src="@/assets/images/home/achievement3.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="block">
        <div class="w">
          <h4>我们的团队</h4>
          <div class="desc">
            目前团队人员70%为产品开发人员，核心骨干来自于腾讯、阿里、百度、恒生电子等公司，在大数据、人工智能、金融软件服务领域积累10年以上的丰富经验。
          </div>
          <div>
            <img src="@/assets/images/home/team_leader.png" alt="" />
          </div>
        </div>
      </div>

      <div class="block">
        <div class="w">
          <h4>联系我们</h4>
          <div class="desc">
            武汉市洪山区光谷大道70号现代光谷世贸中心B栋15楼
          </div>
          <div>
            <img src="@/assets/images/home/address.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
import ScrollReveal from "scrollreveal";
import { getCookie, debounce } from "@/utils/utils.js";
export default {
  data() {
    return {
      trial_visible: false,
      scrollReveal: ScrollReveal(),
      is_login: false,
      mySwiper: null,
      swipeIndex: 0,
    };
  },
  components: {},
  created() {
    if (getCookie("token")) {
      this.is_login = true;
    } else {
      this.is_login = false;
    }
  },
  methods: {
    knowMore(url) {
      window.open(url);
    },
    cutSwipe(index) {
      this.mySwiper.slideTo(index, 1000, false);
      this.swipeIndex = index;
    },
  },
  mounted() {
    const that = this;
    var mySwiper = new Swiper(".swiper", {
      loop: false,
      // effect: "fade",
      lazyLoading: true, //?????
      on: {
        slideChangeTransitionEnd: function () {
          // alert(this.activeIndex); //切换结束时，告诉我现在是第几个slide
          that.swipeIndex = this.activeIndex;
        },
      },
    });
    this.mySwiper = mySwiper;

    // this.$nextTick(() => {
    //   window.addEventListener("scroll", debounce(this.handleScroll, 500), true);
    // });
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
