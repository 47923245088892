var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_us"},[_vm._m(0),_c('section',{staticClass:"part2"},[_vm._m(1),_c('div',{staticClass:"block",staticStyle:{"background":"#f1f7fb"}},[_c('div',{staticClass:"w"},[_c('h4',[_vm._v("灯塔旗下产品")]),_c('ul',{staticClass:"nav"},[_c('li',{class:_vm.swipeIndex == 0 ? 'active' : '',on:{"click":function($event){return _vm.cutSwipe(0)}}},[_vm._m(2),_c('span',[_vm._v("股票灯塔")])]),_c('li',{class:_vm.swipeIndex == 1 ? 'active' : '',on:{"click":function($event){return _vm.cutSwipe(1)}}},[_vm._m(3),_c('span',[_vm._v("灯影")])]),_c('li',{class:_vm.swipeIndex == 2 ? 'active' : '',on:{"click":function($event){return _vm.cutSwipe(2)}}},[_vm._m(4),_c('span',[_vm._v("弹指营")])]),_c('li',{class:_vm.swipeIndex == 3 ? 'active' : '',on:{"click":function($event){return _vm.cutSwipe(3)}}},[_vm._m(5),_c('span',[_vm._v("DOCK分发")])])]),_c('ul',{staticClass:"swipe_box"},[_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._m(6),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"knowmore",on:{"click":function($event){return _vm.knowMore('https://dtying.com/')}}},[_vm._v(" 了解更多 ")])]),_vm._m(7)]),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"knowmore",on:{"click":function($event){return _vm.knowMore('https://tanzhiying.com/index')}}},[_vm._v(" 了解更多 ")])]),_vm._m(8)]),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"knowmore",on:{"click":function($event){return _vm.knowMore('https://dock.tanzhiw.com/')}}},[_vm._v(" 了解更多 ")])]),_vm._m(9)])])])])])]),_vm._m(10),_vm._m(11),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"part1"},[_c('img',{staticClass:"banner_bg",attrs:{"src":require("@/assets/images/home/banner3.png"),"alt":""}}),_c('div',{staticClass:"w"},[_c('div',{staticClass:"text_box",attrs:{"id":"textBox"}},[_vm._v("我们用心提供全面的产品与服务")]),_c('div',{staticClass:"more_icon"},[_c('img',{attrs:{"src":require("@/assets/images/home/more_icon.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"w"},[_c('h4',[_vm._v("企业介绍")]),_c('div',{staticClass:"desc"},[_vm._v(" 灯塔互动于2016年07月01日成立，系灯塔财经信息有限（灯塔财经）旗下全资控股子公司。公司致力于各领域内的科技创新，运用人工智能及大数据，为客户提供全方位的产品和服务，帮助用户搭建从内容创作-渠道分发-获客管理-用户分析-精准营销，商业变现的全流程服务。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',[_c('img',{attrs:{"src":require("@/assets/images/home/nav1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',[_c('img',{attrs:{"src":require("@/assets/images/home/nav2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',[_c('img',{attrs:{"src":require("@/assets/images/home/nav3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',[_c('img',{attrs:{"src":require("@/assets/images/home/nav4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"download"},[_c('div',{staticClass:"android"},[_c('img',{attrs:{"src":require("@/assets/images/home/android.png"),"alt":""}}),_c('div',{staticClass:"android_code"},[_c('img',{attrs:{"src":require("@/assets/images/home/android_code.png"),"alt":""}})])]),_c('div',{staticClass:"ios"},[_c('img',{attrs:{"src":require("@/assets/images/home/ios.png"),"alt":""}}),_c('div',{staticClass:"ios_code"},[_c('img',{attrs:{"src":require("@/assets/images/home/ios_code.png"),"alt":""}})])])])]),_c('div',{staticClass:"show"},[_c('img',{attrs:{"src":require("@/assets/images/home/swipe_dengta.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show"},[_c('img',{attrs:{"src":require("@/assets/images/home/swipe_dengying.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show"},[_c('img',{attrs:{"src":require("@/assets/images/home/swipe_tanzhiying.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show"},[_c('img',{attrs:{"src":require("@/assets/images/home/swipe_dock.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"w"},[_c('h4',[_vm._v("服务成就")]),_c('div',{staticClass:"desc"},[_vm._v(" 灯塔互动于2016年07月01日成立，系灯塔财经信息有限公司（灯塔财经）旗下全资控股子公司。公司致力于各领域内的科技创新，运用人工智能及大数据，为客户提供全方位的产品和服务，帮助用户搭建从内容创作-渠道分发-获客管理-用户分析-精准营销-商业变现的全流程服务。 ")]),_c('div',{staticClass:"achievement"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/home/achievement1.png"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/home/achievement2.png"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/home/achievement3.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"w"},[_c('h4',[_vm._v("我们的团队")]),_c('div',{staticClass:"desc"},[_vm._v(" 目前团队人员70%为产品开发人员，核心骨干来自于腾讯、阿里、百度、恒生电子等公司，在大数据、人工智能、金融软件服务领域积累10年以上的丰富经验。 ")]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/home/team_leader.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"w"},[_c('h4',[_vm._v("联系我们")]),_c('div',{staticClass:"desc"},[_vm._v(" 武汉市洪山区光谷大道70号现代光谷世贸中心B栋15楼 ")]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/home/address.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }